import React from "react" 
import "./intro.scss"

export default function Intro() {

  return (
    <div className='intro' id="intro">
        <div className="image" id="image">
            <img src="assets/IntroFinal1.png" alt="" className="image2" />
        </div>
        
        <div className="text" id="text">

        <img src="assets/MalText.png" alt="" />

        </div>

    </div>
  )
}
 